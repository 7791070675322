<script setup lang="ts">
const { t } = useI18n();
useSeoMeta({
  title: t('pages.home.title'),
  description: t('pages.home.description'),
});
</script>

<template>
  <div>
    <section class="py-20 px-5 light:bg-gray-100 dark:bg-gray-800/50">
      <div class="container mx-auto">
        <h2 class="text-3xl font-semibold mb-4">
          Создайте свой проект
        </h2>
        <p class="light:text-gray-600">
          Геоинформационная система позволяет вам легко создавать проекты и добавлять разные типы точек с различной информацией на карту.
          Например, вы можете отмечать местоположения бетонных заводов и строительных баз.
        </p>
        <!--<UButton class="mt-5" to="/projects/create" size="xl">
          Создать проект
        </UButton>-->
      </div>
    </section>

    <section class="py-20 px-5">
      <div class="container mx-auto text-right">
        <h2 class="text-3xl font-semibold mb-4">
          Прайс-лист товаров
        </h2>
        <p class="light:text-gray-600">
          В каждой точке на карте вы можете добавить информацию о поддерживаемых товарах.
          Например, для бетонного завода это могут быть различные виды бетона и их цены.
        </p>
        <!--<UButton class="mt-5" to="/projects/create" size="xl">
          Создать проект
        </UButton>-->
      </div>
    </section>

    <section class="py-20 px-5 light:bg-gray-100 dark:bg-gray-800/50">
      <div class="container mx-auto">
        <h2 class="text-3xl font-semibold mb-4">
          Рассчитайте стоимость доставки
        </h2>
        <p class="light:text-gray-600">
          Используйте функциональность системы для рассчета стоимости доставки товаров до конкретного адреса.
          Это особенно полезно при работе с различными строительными объектами.
        </p>
        <!--<UButton class="mt-5" to="/projects/create" size="xl">
          Создать проект
        </UButton>-->
      </div>
    </section>
  </div>
</template>

<style scoped></style>
